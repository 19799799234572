<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="d-flex align-self-center justify-center align-center">
          <img
            width="40%"
            src="../../assets/conteudos_tematicos_religiosidade_popular_titulo.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row>
        <nav
          v-if="this.$route.meta.subpage !== true"
          class="container d-flex justify-center align-center pt-3 col-12 col-md-8"
        >
          <v-col
            class="about__page_nav-link d-flex justify-center flex-column align-center"
          >
            <router-link :to="{ name: 'conteudos_tematicos_religiosidade' }">
              Folia de Reis
            </router-link>
          </v-col>
          <v-col class="about__page_nav-link">
            <router-link
              :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
            >
              Calendário Litúrgico
            </router-link>
          </v-col>
        </nav>
      </v-row>
    </section>
    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="A Folia de Reis de Paracatu de Baixo"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  A Folia de Paracatu de Baixo existe há mais de um século,
                  levando a devoção ao Menino Jesus aos lares de comunidades e
                  distritos de Mariana-MG, sendo uma das celebrações religiosas
                  mais importantes da região.
                </p>
                <p>
                  Desde a década de 1950, José Patrocínio de Oliveira assumiu a
                  função de capitão da Folia, ou seja, aquele que puxa o canto
                  das toadas, além de se encarregar da organização e manutenção
                  do grupo de fiéis. Conhecido por seu Zezinho, ou Zé Telheiro,
                  devido ao seu ofício na construção prática de casas, ele
                  nasceu no ano de 1930 em Paracatu de Baixo, subdistrito de
                  Mariana, onde passou toda a sua vida. Zezinho começou a
                  acompanhar os folieiros com nove anos de idade e,
                  posteriormente, passou a ser o Capitão do cortejo, guiando o
                  grupo ano após ano. Desde então, a Folia de Paracatu de Baixo
                  tem atraído centenas de devotos e visitado inúmeros lares de
                  localidades vizinhas.
                </p>
                <p>
                  Como Capitão, coube a seu Zezinho promover a união e a
                  motivação do grupo, a definição do trajeto da peregrinação, a
                  conformação de todo o ritual festivo-religioso e, sobretudo, a
                  continuidade e a transmissão do conhecimento popular dos
                  saberes para as novas gerações. Ao lado do Capitão, agregou-se
                  um grupo rotativo de aproximadamente quinze folieiros:
                  porta-bandeira, palhaço, cantores e músicos — tocadores de
                  caixa (também chamado de bumba), pandeiro, xique-xique,
                  sanfona, violão e viola. Zezinho tocava a caixa na maioria das
                  vezes e também portava a bandeira, levando-a durante a
                  caminhada.
                </p>
                <p>
                  O cortejo da Folia de Paracatu tradicionalmente ocorre de 26
                  de dezembro até 6 de janeiro, dia de Santos Reis. A rota
                  perpassa comunidades vizinhas como Monsenhor Horta, Águas
                  Claras, Paracatu de Cima, Pedras, Furquim, Borba e Ponte do
                  Gama.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_01.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Dia de festa em Paracatu. Foto: Wan Campos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_02.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center">
            Seu Zezinho em primeiro plano, com a caixa da folia, na festa do
            Menino Jesus. Ao fundo, o ônibus fornecido pela Fundação Renova para
            levar os atingidos a Paracatu para realizar os festejos. Foto: Joice
            Valverde, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Por mais de meio século, Seu Zezinho foi liderança
                  reverenciada em toda a região, responsável também pela Festa
                  do Menino Jesus. No mês de junho, ele já começava a organizar
                  o festejo, valendo-se das esmolas da Folia realizada no ciclo
                  natalino anterior. Era no giro do ciclo natalino que seu
                  Zezinho convidada as comunidades vizinhas e outros grupos de
                  folias e congados para a festa de Menino Jesus em setembro<sup
                    >1</sup
                  >
                  do ano seguinte. Esta festa é realizada desde 1961 e já está
                  consolidada no
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_religiosidade_calendario',
                    }"
                    >calendário litúrgico</router-link
                  >
                  da região. Quando os recursos eram insuficientes, seu Zezinho
                  financiava do próprio bolso os custos restantes.
                </p>
                <p>
                  A festa para o Menino Jesus era realizada em preparação para o
                  giro da Folia e recebia o congado, bandas filarmônicas (como a
                  Sociedade Musical de São Caetano, de Monsenhor Horta) e
                  folieiros de outras comunidades. Naquela celebração,
                  aconteciam danças de fitas, eram instalados brinquedos para
                  crianças e tocava-se música na praça. Igualmente, a Folia de
                  Paracatu de Baixo era convidada a participar dos festejos das
                  comunidades vizinhas, compondo uma série de eventos litúrgicos
                  e fortalecendo as relações de troca entre as comunidades.
                </p>
                <p>
                  O lar de Zezinho abrigava todos os instrumentos musicais e
                  demais objetos sagrados dos folieiros, além de ser o ponto de
                  encontro de onde se iniciava a caminhada e, de maneira mais
                  ampla, se concentrava a sociabilidade do povoado, sendo
                  frequentado por toda a comunidade católica da região.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A estrutura da casa de seu Zezinho em Paracatu de Baixo
                  refletia a centralidade que a Folia tinha na sua vida, bem
                  como a sua própria centralidade nos festejos, que chegavam a
                  receber mais de quinhentas pessoas. O presépio, a bandeira e
                  os instrumentos ficavam expostos na sala principal da sua
                  residência, para visitação dos devotos. Aí se encontravam e se
                  alimentavam os envolvidos na preparação do grande almoço da
                  festa, servido a toda comunidade. Anexo à casa, havia um
                  barracão de três cômodos, coberto de telha, onde se guardavam
                  os instrumentos musicais, indumentárias, livros de cânticos,
                  bandeiras, imagens, documentação e registros tanto da Folia,
                  quanto do cemitério local, do qual seu Zezinho era
                  responsável. Todas as telhas das construções eram produzidas
                  pelo próprio Zezinho, mestre artífice da construção
                  tradicional, que sabia fazer “todo tipo de coisa”, inclusive o
                  conserto dos instrumentos do grupo.
                </p>
                <p>
                  Havia também um barraco de dois cômodos para a guarda dos
                  mantimentos usados para alimentar a vultosa quantidade de
                  frequentadores da festa. Seu Zezinho contribuia ainda com um
                  boi para o almoço. Em outra cobertura, se guardava a lenha e
                  se preparava a comida. A trempe de chão (três pedras grandes
                  fincadas no solo) sustentava o grande tacho sob o qual se
                  acendia o fogo e se cozinhava feijão e carne, enquanto no
                  fogão à lenha cozinhavam-se os demais complementos. Havia mais
                  uma cobertura onde se distribuía a refeição, com bancos para
                  que as pessoas sentassem. Todo o ambiente era enfeitado de
                  bandeirinhas coloridas que ornavam a festividade.
                </p>
                <p>
                  Entretanto, no final do ano de 2015, a comunidade de Paracatu
                  de Baixo, localizada a 25 km da sede do município de Mariana,
                  foi soterrada pelo rejeito tóxico do rompimento da barragem de
                  Fundão da Samarco, empresa de propriedade das multinacionais
                  Vale e BHP Billiton, duas das mineradoras mais lucrativas do
                  mundo. A residência do patriarca foi totalmente destruída pela
                  lama. Nas palavras de Maria Geralda, filha de Zezinho: “A lama
                  levou a casa de porteira fechada”.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_03.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Dona Balduína, seu Zezinho, Elias e Dona Laura. Foto: Wan Campos,
            2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_04.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center">
            Mastro da bandeira. Foto: Wigde Arcângelo, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Com a casa, foram-se todos os bens salvaguardados por Zezinho:
                  tanto da Folia — como registros escritos e fotográficos,
                  trajes e instrumentos –, quanto do cemitério — como livros de
                  tombo, certidões de óbito e guias de enterramento, documentos
                  antigos que testemunhavam parte fundamental da história dos
                  moradores de Paracatu.
                </p>
                <p>
                  Dias após a passagem da lama, Zezinho retornou com os filhos à
                  sua comunidade devastada, conseguindo salvar apenas a bandeira
                  do Menino Jesus, intacta, que hoje carregam como um símbolo de
                  sobrevivência e resistência da fé popular. A bandeira foi
                  vista como um milagre anunciando a continuidade da Folia, que,
                  aos poucos vem sendo reconstruída pelos filhos de seu Zezinho,
                  com auxílio do grupo "Amigos do Menino Jesus”, e parco apoio
                  institucional da Fundação Renova.
                </p>
                <p>
                  Também foi encontrado no meio da lama o mastro da bandeira, o
                  qual foi lavado pela família. Tendo nas celebrações religiosas
                  a motivação da sua vida, Zezinho não esperou, e com recursos
                  próprios comprou toalhas para a igreja, um andor e três arcos
                  para as bandeiras de Menino Jesus, Santo Antônio e Nossa
                  Senhora Aparecida, entre outros itens, que hoje estão sob a
                  guarda de sua filha Maria Geralda. Completamente despojada dos
                  seus recursos materiais, a Folia conseguiu ainda reunir
                  doações para seguir a sua peregrinação, como sanfonas e
                  percussões industriais. Entretanto, alguns instrumentos
                  adquiridos após o rompimento foram perdidos, porque já não
                  havia local adequado para o seu armazenamento. E ainda, a
                  viola, entre outros bens, até hoje não conseguiram reaver.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O desastre-crime fez com que todos os habitantes de Paracatu
                  de Baixo fossem desterritorializados, espalhados por Mariana e
                  seus distritos, com graves consequências para a sociabilidade
                  da comunidade, em especial para a Folia, essencialmente movida
                  pelo sentimento de localidade. Seu Zezinho passou a viver em
                  uma casa alugada no centro da cidade de Mariana, enquanto
                  aguardava a indenização e reassentamento.
                </p>
                <p>
                  Seu Zezinho faleceu em 2021, com 91 anos, sem ter recuperado
                  completamente os seus bens materiais e sagrados, subitamente
                  espoliados pela consequência nefasta da atividade mineradora
                  na região onde nasceu e viveu toda a sua vida. Atualmente, os
                  filhos de Zezinho, sobretudo Nié, Maria Geralda e Elias, são
                  os responsáveis pela Folia, tendo se comprometido a não deixar
                  a folia acabar, em respeito ao desejo do seu pai que sempre
                  rogou para que dessem continuidade ao festejo.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_05.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Crianças correm do palhaço. Foto: Wigde Arcângelo. 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_06.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center">
            Caixa. Foto: Joice Valverde, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#426E51"
              title="Instrumentos e indumentárias"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Dentre os vários instrumentos musicais que o Sr. Zezinho
                  guardava com muito zelo para o grupo de festeiros tocar todos
                  os anos, havia sanfona, viola, violão, bumba, pandeiro e
                  xique-xique. Quando era preciso, ele mesmo confeccionava os
                  instrumentos e realizava a manutenção.
                </p>
                <p>
                  Zezinho trocava as cordas e tarrachas dos pandeiros, bumbas,
                  violas e violões, além de preparar o couro de boi dos
                  instrumentos percussivos, colocando-o para curtir e amolecer,
                  retirando os pelos e substituindo o couro antigo. Ele também
                  havia inventado outro tipo de bumba, feita de latão de tinta,
                  que era mais leve, facilitando o giro do grupo por vastas
                  distâncias, e que ecoava desde mais longe, avisando a chegada
                  da Folia.
                </p>
                <p>
                  Zezinho extraía as fibras da piteira (agave), planta comum na
                  região, para produzir as cordas em sisal, que depois eram
                  tramadas para confeccionar as alças das caixas (bumbas). O
                  processo envolvia extrair as folhas da piteira, secá-las ao
                  sol, quebrá-las e soltar as fibras, lavando-as para retirar o
                  sumo e, com o material já seco, faziam-se as tranças de sisal.
                </p>
                <p>
                  Atualmente, todos os instrumentos da Folia são industriais,
                  uma vez que aqueles de “pau e corda” foram levados pela lama
                  de rejeitos. Com o recente falecimento do patriarca,
                  interrompeu-se também a transmissão dos saberes e fazeres
                  associados à Folia, como a produção desses instrumentos.
                </p>
                <p>
                  A Folia possuía seis sanfonas, instrumentos melódicos mais
                  importantes do festejo. Durante o giro, os folieiros sempre
                  levavam duas sanfonas, para substituição, caso ocorresse algum
                  defeito em uma delas. Os instrumentos são enfeitados com fitas
                  coloridas, assim como se ornamenta a bandeira, ainda hoje, com
                  fitas, rendas e flores.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A indumentária do grupo também foi totalmente destruída pela
                  lama, de modo que, atualmente, os folieiros utilizam roupas
                  comuns. O único que atualmente traja indumentária é Zé Nestor,
                  o palhaço da Folia, usando um macacão vermelho com gola
                  amarela e máscara de macaco.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_07.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Zé Nestor com a roupa atual do palhaço. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          class="assessoria_card d-flex flex-column justify-start align-start"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_religiosidade_08.png"
            alt=""
          />
        </v-col>

        <v-col
          class="assessoria_card d-flex flex-column justify-start align-start pos-relative"
          cols="12"
          md="3"
        >
          <img
            width="100%"
            src="../../assets/conteudos_religiosidade_09.png"
            alt=""
          />
          <p class="pos-absolute" style="font-size: 0.6em;">
            Diferentes máscaras utilizadas pelo palhaço. Fotos: Elias Oliveira,
            sem data.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center mt-5 mt-md-0"
        >
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Mas esta nem sempre foi a roupa do palhaço. Em fotos de anos
                  anteriores, notamos outras vestimentas, de cetim amarelo com
                  chita, e máscara artesanal, com chapéu cônico, emulando um
                  animal com a língua de fora.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Zé Nestor conta que acompanha a Folia desde criança, sendo
                  integrante do grupo há 20 anos. Iniciou tocando caixa, mas
                  logo recebeu a atribuição de ser o palhaço, presença
                  requisitada por seu Zezinho. Hoje é quem faz graça para as
                  crianças que ficam interessadas na figura mascarada.
                </p>
                <p>
                  Uma brincadeira típica é as crianças darem um ovo cru ao
                  palhaço, que, depois de bebê-lo, corre atrás dos meninos e
                  meninas.
                </p>
                <p>
                  Mesmo com vestimenta ordinária, seu Zezinho manteve o uso do
                  chapéu e echarpe vermelha. Após o seu falecimento, os objetos
                  remanescentes e adquiridos foram entregues a Nié, em ocasião
                  da Folia realizada em dezembro de 2021, no interior da igreja
                  de Paracatu de Baixo, em ritual simbólico de transmissão do
                  cargo de Capitão.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_10.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Seu Zezinho. Destaque para o chapéu e a echarpe que eram sempre
            utilizados durante as atividades da folia. Foto: Wan Campos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#426E51"
              title="Cantos"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Nos grupos de folia (companhias, ternos, embaixadas ou
                  bandeiras), é o Capitão que tem a função de conduzir a
                  caminhada (peregrinação ou giro) dos fiéis, através da qual se
                  reconstrói simbolicamente a busca dos Reis Magos pelo Menino
                  Jesus, distribuindo suas bênçãos durante o ciclo natalino.
                </p>
                <p>
                  É vasto o repertório do grupo de Paracatu de Baixo, executando
                  cerca de 60 músicas. A maioria delas foi criada na própria
                  comunidade, mas por vezes também entoam cantos de outras
                  Folias. A quantidade de músicas cantadas nas paradas depende
                  da esmola ofertada. A Sra. Maria Geralda elucidou que:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image"
        >
          <p class="ml-4">
            “(...) a primeira música é cantando como a gente veio fazer uma
            visita (...), depois a gente canta pedindo a oferta, depois a gente
            canta uma outra agradecendo, depois despedindo pra ir embora”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O puxador das músicas era o mestre Zezinho, mas em razão do
                  seu falecimento, os seus filhos Nié e Elias são os
                  responsáveis hoje por “puxar” as músicas, que logo em seguida
                  são respondidas pelos outros integrantes.
                </p>
                <p>
                  Como em outros grupos, os cantos dos folieiros de Paracatu são
                  estruturados de forma a duplicar cada verso antes de entoar o
                  próximo. Essa maneira de cantar permite que os devotos
                  respondam, mesmo sem conhecer a música. Abaixo você pode ler e
                  escutar o canto de chegada entoado por Elias:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex flex-column justify-center align-center">
            <div
              width="100%"
              class="d-flex justify-space-around audio-player"
              style="background-color: #1b211b; padding: 0.5em; border-radius: 8px 8px 0 0;"
            >
              <img width="15%" src="./assets/music_image_1.png" alt="" />
              <div class="d-flex flex-column justify-space-around align-start">
                <p style="color: white;">Nossa bandeira foi chegando</p>
                <img width="400px" src="./assets/sound_waves.png" alt="" />
              </div>
            </div>
            <audio controls>
              <source
                src="../../assets/nossa_bandeira_foi_chegando.ogg"
                type="audio/ogg"
              />
            </audio>
          </div>
        </v-col>
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>Nossa bandeira foi chegando</span>
              <span>Nossa bandeira foi chegando</span>
              <span>E ela foi bem recebida</span>
              <span>E ela foi bem recebida</span>
              <span>Eu venho pedir uma esmola</span>
              <span>Eu venho pedir uma esmola</span>
              <span>Para fazer a festa dele</span>
              <span>Para fazer a festa dele</span>
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_religiosidade_11.png" alt="" />
          <p style="font-size: 0.6em; text-align: center;">
            A Folia de Paracatu no centro de Mariana. Foto: Elias Oliveira, sem
            data.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Após feita a oferta, canta-se para agradecer a generosidade do
                  anfitrião.
                </p>
                <p>
                  Os folieiros anunciam também seu retorno no ano vindouro.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <div class="d-flex flex-column justify-center align-center">
              <div
                width="100%"
                class="d-flex justify-space-around audio-player"
                style="background-color: #1b211b; padding: 0.5em; border-radius: 8px 8px 0 0;"
              >
                <img width="15%" src="./assets/music_image_1.png" alt="" />
                <div
                  class="d-flex flex-column justify-space-around align-start"
                >
                  <p style="color: white;">Agradecendo Esmola</p>
                  <img width="400px" src="./assets/sound_waves.png" alt="" />
                </div>
              </div>
              <audio controls>
                <source
                  src="../../assets/agradecendo_esmola.ogg"
                  type="audio/ogg"
                />
              </audio>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>E nós viemos agradecendo</span>
              <span>Nós viemos agradecendo</span>
              <span>A esmola que você deu</span>
              <span>A esmola que você deu</span>
              <span>Quando for o outro ano</span>
              <span>Quando for o outro ano</span>
              <span>E nós, ê vai tornar a voltar</span>
              <span>E nós, ê vai tornar a voltar</span>
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_religiosidade_12.png" alt="" />
          <p style="font-size: 0.6em; text-align: center;">
            Nié e Elias dão continuidade à devoção do pai. Foto: Paula Zanardi,
            2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="9" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Simples na sua base harmônica, a riqueza musical das Folias
                  encontra-se especialmente “na sobreposição e encaixe de
                  vozes”<sup>2</sup>, que segue o canto do mestre, muitas vezes
                  improvisado, envolto por uma complexa rede de códigos e
                  símbolos do catolicismo popular.
                </p>
                <p>
                  Como apontado por Rios e Viana, “os grupos de Folia de Reis
                  preservam, há várias gerações, cantos, toadas e batidas com as
                  cores e sabores específicos das localidades em que surgiram e
                  das circunstâncias de sua difusão”<sup>3</sup>. Com o
                  significativo incremento dos índices de alfabetização,
                  tornou-se mais comum que os foliões guardem um caderno com as
                  letras dos hinos e cânticos, como um patrimônio do grupo.
                  Entretanto, os saberes e fazeres essenciais das folias,
                  especialmente o ofício do mestre, só pode ser apreendido pela
                  vivência experienciada entre os foliões. A dinâmica da
                  oralidade (que não se restringe ao falar e ao ouvir, mas
                  abrange as diversas formas de expressões corporais)
                  caracteriza não apenas o aprendizado nas folias, sendo a
                  principal forma de reprodução e prática das manifestações
                  populares.
                </p>
                <p>
                  Assim, não há espaço ou momento institucionalizado, nem uma
                  pessoa especialmente dedicada ao ensino nas tradições
                  populares. As folias não são regidas por “práticas
                  deliberadamente organizadas e vertidas para o
                  ensino”<sup>4</sup>, mas por “formas próprias de coesão social
                  que são essencialmente produção de sentidos e de
                  saberes”<sup>5</sup>.
                </p>
                <p>
                  Nas folias, e demais folguedos, prevalece o processo informal
                  e natural de transmissão de conhecimento, no qual “as pessoas
                  convivem umas com as outras e o saber flui, pelos atos de quem
                  sabe-e-faz, para quem não-sabe-e-aprende”, tal como descrito
                  por Carlos Brandão. “Mesmo quando os adultos encorajam e guiam
                  os momentos e situações de aprender de crianças e
                  adolescentes, são raros os tempos especialmente reservados
                  apenas para o ato de ensinar”<sup>6</sup>.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="História"
              borderColor="#426E51"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Das celebrações do catolicismo popular, a mais encontrada
                  atualmente, de norte a sul do Brasil, é a Folia de Reis ou
                  Reisado. As folias são mais comumente dedicadas aos Santos
                  Reis, mas podem homenagear diferentes santidades católicas,
                  como São Sebastião, Divino Espírito Santo, Nossa Senhora ou
                  Menino Jesus — este o caso da Folia de Paracatu de Baixo.
                  Disseminadas por todo o país, as folias constituíram-se em uma
                  das mais importantes celebrações do catolicismo nas
                  comunidades rurais brasileiras, assumindo uma ampla
                  diversidade de formas culturais.
                </p>
                <p>
                  Como muitos dos ritos religiosos populares praticados desde o
                  período colonial, as folias sofreram um processo histórico de
                  rejeição e afastamento gradual dos grandes centros urbanos,
                  encontrando refúgio no interior do país, onde passaram a ser
                  integradas às culturas rurais. O restrito número de clérigos
                  para suprir as demandas devocionais de uma população tão
                  dispersa na vastidão do sertão levou à necessidade de leigos
                  substituírem os sacerdotes, criando as suas próprias
                  instituições e multiplicando a atividade de benzedores,
                  rezadores e foliões, que reinterpretavam, à sua maneira e
                  boa-fé, os ensinamentos do evangelho. Na religiosidade
                  sincrética do sertão, os santos ocuparam uma posição
                  privilegiada e íntima dos fiéis, responsáveis por prover a
                  proteção diante das incertezas do cotidiano caboclo.
                </p>
                <p>
                  Assim, as folias assumiram a função essencial de reforçar os
                  laços de solidariedade entre os membros de determinada
                  vizinhança, integrando-os em um amplo sistema de trocas
                  cerimoniais que periodicamente reiteram as relações de
                  prestações recíprocas entre os moradores. Os valores
                  comunitários basilares, que se encontram subsumidos nas
                  práticas de devoção e fé das folias, têm sido cruciais para a
                  manutenção da memória no violento processo de desenraizamento,
                  como estratégias para evitar ou amenizar o esgarçamento
                  identitário das comunidades postas em movimento pela migração.
                </p>
                <p>
                  Nesse processo, o interesse das novas gerações é vital para a
                  manutenção das tradições culturais populares, tendo em vista
                  que essa manutenção depende fundamentalmente da transmissão
                  oral de saberes e práticas. Para que essa transmissão ocorra,
                  é necessário a existência daqueles a quem costumamos chamar de
                  mestres. Como afirma Maria Isaura Pereira de Queiroz, “a
                  importância destes portadores de elementos folclóricos é muito
                  grande, e vimos que a decadência do folclore pode se associar,
                  entre nós, à partida deles, abandonando seus bairros para se
                  fixar em zonas diferentes ou na cidade”<sup>7</sup>.
                </p>
                <p>
                  Acompanhando as progressivas transformações na estrutura
                  social, tais práticas tradicionais foram recriadas, visando a
                  sobrevivência. Em certas comunidades, lograram resistir frente
                  às novas condições sociais; em outras, desapareceram com a
                  sociabilidade camponesa que lhes infundia sentido.
                  Ressurgiram, entretanto, em outros espaços, acompanhando o
                  movimento demográfico dos migrantes, detentores daqueles
                  saberes, como é o caso da migração forçada dos habitantes de
                  Paracatu de Baixo.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_religiosidade_13.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            A Folia de Paracatu no centro de Mariana. Foto: Elias Oliveira, sem
            data.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="9" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#426E51"
              title="O Futuro da Folia"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Como nas demais folias, a longa jornada natalina da
                  peregrinação dos devotos de Paracatu de Baixo constitui-se em
                  um árduo sacrifício para que seja alcançada a bênção divina ao
                  se renovar todo ano a crença no Menino Jesus. Em condições
                  normais das folias espalhadas pelo país, já não é nada fácil
                  agregar os fiéis, especialmente os mais jovens, perante a
                  exigência de um mundo cada vez mais globalizado.
                </p>
                <p>
                  Nesse sentido, a brusca ruptura ocorrida em Paracatu de Baixo
                  se apresenta como um desafio imenso, porém de superação
                  plausível e primordial, para que os devotos consigam dar
                  continuidade às suas tradições. Com o falecimento do Capitão
                  Zezinho, atualmente a Folia de Paracatu vive um momento
                  crucial para a sua continuidade.
                </p>
                <p>
                  Vale ressaltar que o valor histórico e identitário da Folia de
                  Paracatu de Baixo obteve o reconhecimento do poder público em
                  2016, ao ser cadastrada no inventário do Instituto Estadual do
                  Patrimônio Histórico e Artístico de Minas Gerais (IEPHA-MG)
                  como patrimônio imaterial mineiro a ser preservado.
                </p>
                <p>
                  Entretanto, parte significativa dos bens da Folia de Paracatu
                  de Baixo ainda não foi recuperada. Entre os instrumentos
                  perdidos, destaca-se a viola, tida como um instrumento sagrado
                  entre os folieiros. A ausência desse instrumento na Folia
                  representa uma enorme perda da sonoridade característica e de
                  todo o simbolismo divino em torno da viola caipira, além de
                  impedir a transmissão dos saberes e técnicas tradicionais da
                  prática da viola às novas gerações. Na perda da viola,
                  consubstancia-se a dramática necessidade de adaptação da
                  Folia, imposta pelas condições tremendamente adversas de
                  desterritorialização, no empenho de garantir a sua
                  sobrevivência, movidos pela força de sua fé.
                </p>
                <p>
                  Apesar de todas as transformações vivenciadas pela Folia após
                  o rompimento, a Festa do Menino Jesus e os giros da Folia
                  continuam a ser realizados em um clima de festa e alegria. O
                  futuro da Folia de Reis de Paracatu de Baixo ainda é incerto,
                  fica a ver como a comunidade dará continuidade aos festejos
                  após o reassentamento. Mas, como nos ensina Alfredo Bosi,
                  “enquanto há e enquanto houver um cotidiano popular e rústico,
                  a tradição se reapresenta e se reelabora, não como uma
                  reprodução compulsiva do passado, mas como respostas às
                  carências sofridas pela comunidade”<sup>8</sup>.
                </p>
                <p style="text-align: center;">
                  <strong
                    >Autores: Luiz Antonio Guerra<sup>9</sup> e Paula Pflüger
                    Zanardi <sup>10</sup>
                  </strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> O festejo para Menino Jesus era realizado em
              dezembro, no contexto natalino. Contudo, por ser uma época de
              fortes chuvas na região, a festa foi transferida para setembro, no
              intuito de garantir a presença dos visitantes.
            </p>
            <p><sup>2</sup> RIOS; VIANA, 2015, p. 17.</p>
            <p><sup>3</sup> Ibidem.</p>
            <p><sup>4</sup> DIAS, 2010,p. 56.</p>
            <p><sup>5</sup> PESSOA, 2007, p. 63.</p>
            <p><sup>6</sup> BRANDÃO, 2007, p. 18.</p>
            <p><sup>7</sup> QUEIROZ, 1973, p. 136.</p>
            <p><sup>8</sup> BOSI, 1993, p. 19.</p>
            <p>
              <sup>9</sup> Pesquisador em gestão de políticas públicas, cultura
              popular e música brasileira, doutor em Sociologia pela USP.
            </p>

            <p>
              <sup>10</sup> Cientista Social pela UFSC, mestra em Preservação do
              Patrimônio Cultural pelo IPHAN. Assessora técnica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong> Referências Bibliográficas </strong></p>
            <p>
              BOSI, Alfredo. Mestre Xidieh. In: XIDIEH, Oswaldo Elias.
              <strong>Narrativas populares</strong>. Introdução. Belo Horizonte
              Itatiaia; São Paulo: Edusp, 1993.
            </p>
            <p>
              BRANDÃO, Carlos Rodrigues. <strong>Sacerdotes de Viola</strong>.
              Petrópolis: Vozes, 1981.
            </p>
            <p>
              BRANDÃO, Carlos Rodrigues. <strong>O que é educação</strong>. São
              Paulo: Brasiliense, 2007. Coleção Primeiros Passos; 20. 49ª
              reimpr. da 1. ed.
            </p>
            <p>
              CÉSARI, Carolini.
              <strong>Folia de Reis - Paracatu de Baixo</strong>. Minas Gerais,
              Estilo Nacional; Fundação Renova, sem data.
            </p>
            <p>
              DIAS, Saulo Sandro Alves.
              <strong>O processo de escolarização da viola caipira</strong>:
              novos violeiros (in)ventano moda e identidades. São Paulo, 2010.
              Tese (Doutorado em Educação) – Universidade de São Paulo.
            </p>
            <p>
              GUERRA, Luiz Antonio. <strong>Mestres de ontem e de hoje</strong>:
              uma sociologia da viola caipira. São Paulo, 2010. Tese (Doutorado
              em Sociologia) – Universidade de São Paulo.
            </p>
            <p>
              IEPHA.
              <strong
                >Dossiê para registro das Folias de Minas do estado de Minas
                Gerais</strong
              >. Belo Horizonte: Instituto Estadual do Patrimônio Histórico e
              Artístico de Minas Gerais, 2016.
            </p>
            <p>
              IEPHA.
              <strong
                >Dossiê para registro dos saberes, linguagens e expressões
                musicais da viola em Minas Gerais</strong
              >. Belo Horizonte: Instituto Estadual do Patrimônio Histórico e
              Artístico de Minas Gerais, 2018.
            </p>
            <p>
              IKEDA, Alberto T. <strong>Folia de reis, sambas do povo</strong>.
              São José dos Campos: CECP/FCCR, 2011.
            </p>
            <p>
              PESSOA, Jadir de Morais.
              <strong>Mestres de caixa e viola</strong>. Cad. Cedes, Campinas,
              vol. 27, n. 71, p. 63-83, jan./abr. 2007.
            </p>
            <p>
              RIOS, Sebastião; VIANA, Talita.
              <strong>Toadas de Santos Reis em Inhumas, Goiás</strong>:
              tradição, circulação e criação individual. Goiânia: Gráfica UFG,
              2015.
            </p>
            <p>
              QUEIROZ, Maria Isaura Pereira.
              <strong>Bairros rurais paulistas</strong>: dinâmica das relações
              bairro-rural-cidade. São Paulo: Duas Cidades, 1973.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Calendário Litúrgico"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },

  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Religiosidade Popular / ",
          href: "conteudos_tematicos_religiosidade",
        },
        {
          text: "Folia de Reis",
          href: "conteudos_tematicos_religiosidade_folia",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__mb {
  margin-bottom: 3em;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
  bottom: -2em;
  right: 0;
  text-align: center;
  width: 200%;
  margin-bottom: 0;
  font-size: 0.7em;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_religiosidade_15.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 0 2em 0 3em;
}

.poem {
  border: 1px solid red;
  padding: 2em;
  width: 20em;
  border-radius: 40% 40% 40% 40%;
  background: white;
}

.poem p span {
  display: block;
  font-size: 0.8em;
  text-align: center;
  font-weight: bold;
  line-height: 40px;
}

@media (max-width: 959px) {
  .pos-absolute {
    position: relative;
    bottom: -1em;
    right: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 0;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

audio {
  height: 50px;
  width: 100%;
  background: #f1f3f4;
}

a {
  color: #426e51;
}

.router-link {
  color: #426e51;
}
</style>
